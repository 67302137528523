.create-item-top-section-wrapper {
  background-color: #f7f7f7;
}

.total-section {
  background-color: #f7f7f7;
  padding-top: 1rem;
}

.payment-pdf-section {
  background-color: #f7f7f7;
  padding: 1rem;
  margin: 0 auto;
}

// .ant-card-body {
//   padding-top: 0 !important;
// }

// Login
.container {
  max-width: 408px;
  margin: auto;
}

.title {
  text-align: center;
  color: #626262;
  font-size: 30px;
  letter-spacing: -0.04em;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.featureWrapper{ 
  max-width: 1280px;
  margin-block: 3rem;
  margin-inline: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap:2rem;
}

// header

.header-right {
  position: relative;
}

.header-right__buttons {
  display: flex;
  gap: 10px;
}

.header-right__dropdown {
  display: none;
}

.header-right__toggle {
  background: none;
  border: none;
  cursor: pointer;
}

.header-right__icon {
  width: 24px;
  height: 24px;
}

.header-right__menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 99;
}

// Register Page
.register-layout-content {
  background: #fff;
  padding: 0 2em;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.footer-link:hover {
  color: "#fff";
  opacity: 0.5;
}

// network page

.networkWrapper .childNetwork{
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  justify-content: center;
  gap:16px;
}

.networkWrapper .childNetwork > a{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid gainsboro;
  padding:12px;
  border-radius: 8px;
  gap:16px;
  // box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  transition: all linear 0.3s;
}

.networkWrapper .childNetwork > a:hover {
  border: 1px solid #5EC82A;
}

.networkWrapper .childNetwork > a img{
  height: 120px;
  transition: all linear 0.3s;
}

.networkWrapper .childNetwork > a p{
  color:rgb(0 0 0 / 85%);
  margin:0;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.4;
  flex: 1 1 0%;
  transition: all linear 0.3s;
}

.networkWrapper .childNetwork > a:hover img{
  transform: scale(1.1);
}

.networkWrapper .childNetwork > a:hover p{
  color: #5EC82A;
}

footer .footerLeft, footer .footerRight{
  flex:1;
}

footer .footerRight{
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

@media screen and (max-width: 1280px) {

  .networkWrapper .childNetwork{
    gap:8px;
  }
  .networkWrapper .childNetwork > a p {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.4;
}
}

@media screen and (max-width: 1024px) {

  .networkWrapper .childNetwork{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .networkWrapper .childNetwork{
    gap:8px;
  }

  .networkWrapper .childNetwork > a{
    padding:4px;
  }
}

@media screen and (max-width: 768px) {
  .networkWrapper .childNetwork, .featureWrapper{
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  footer .footerLeft{
    width: 100%;
    flex: none;
  }

  .footer .footerRight{
    width: 100%;
    flex: none;
    justify-content: start;
  }
}

@media screen and (max-width: 640px) {
  .header-right__buttons {
    display: none;
  }

  .header-right__dropdown {
    display: block;
  }

}
