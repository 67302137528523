@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";

// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

// font
@font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Ubuntu, sans-serif;

// base variables
@border-radius-base: 6px;

// colors
@primary-color: #67be23;
// @primary-color: #3fa9ff;
@error-color: #fa541c;
@text-color: #626262;

// sider
@layout-sider-background: #ededed;
@menu-bg: transparent;
@menu-item-color: #000;
@menu-inline-submenu-bg: transparent;
@menu-highlight-color: #000;
@menu-item-active-bg: transparent;
@menu-item-active-border-width: 0px;
@layout-trigger-background: rgba(0, 0, 0, 0.5);

//form
@label-color: @text-color;

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px;
}

.ant-form-item-label > label {
  font-weight: bold;
}

.ant-page-header {
  border-radius: @border-radius-base;
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #c8bdca;
}
.ant-menu-tree-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: #fff;
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-tree-arrow::before,
.ant-menu-tree-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: "";
}
.ant-menu-tree-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-tree-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}

.text-link {
  color: @text-color!important;
}

// Heading
.ant-page-header-heading-title {
  font-size: 18px;
}
